import { language } from "../language";
import convertArrToObj from "../utils/convertArrToObj";

export const MODULE_UNIT_GROUP = "UNIT_GROUP";
export const MODULE_UNIT = "UNIT";
export const MODULE_RESIDENT = "RESIDENT";
export const MODULE_ROLE = "ROLE";
export const MODULE_PERMISSION = "PERMISSION";
export const MODULE_GROUP_PERMISSION = "GROUP_PERMISSION";
export const MODULE_MODULE = "MODULE";
export const MODULE_API = "API";
export const MODULE_USER = "USER";
export const MODULE_ICON = "ICON";
export const MODULE_VERSION = "VERSION";
export const MODULE_VISITER = "VISITER";
export const MODULE_HELP_DESK = "HELP_DESK";
export const MODULE_EMPLOYEE = "EMPLOYEE";
export const MODULE_CUSTOMER = "CUSTOMER";
export const MODULE_ADS_PARTNER = "ADS_PARTNER";
export const MODULE_PARTNER_ORDER = "PARTNER_ORDER";
export const MODULE_MERCHANT_ORDER = "MERCHANT_ORDER";
export const MODULE_ADS_SERVICE = "ADS_SERVICE";
export const MODULE_HOT_DEAL_CATEGORY = "HOT_DEAL_CATEGORY";
export const MODULE_HOT_DEAL = "HOT_DEAL";
export const MODULE_PARTNER = "PARTNER";
export const MODULE_TASK = "TASK";
export const MODULE_WHITE_LABEL = "WHITE_LABEL";
export const MODULE_NOTIFICATION = "NOTIFICATION";
export const MODULE_COUNTRY = "COUNTRY";
export const MODULE_CITY = "CITY";
export const MODULE_DISTRICT = "DISTRICT";
export const MODULE_WARD = "WARD";
export const MODULE_BILL_LOCK = "BILL_LOCK";
export const MODULE_REMOTE_CONFIG = "REMOTE_CONFIG";
export const MODULE_PLAN = "PLAN";
export const MODULE_MERCHANT = "MERCHANT";
export const MODULE_MERCHANT_UTILITY = "MERCHANT_UTILITY";
export const MODULE_STORAGE = "STORAGE";
export const MODULE_PAYMENT_PROVIDER = "PAYMENT_PROVIDER";
export const MODULE_ORGANIZATION = "ORGANIZATION";
export const MODULE_SURVEY = "SURVEY";
export const MODULE_PROMOTION = "PROMOTION";

export const MODULES = [
  {
    id: MODULE_UNIT_GROUP,
    name: language.building,
  },
  {
    id: MODULE_UNIT,
    name: language.apartment,
  },
  {
    id: MODULE_RESIDENT,
    name: language.resident,
  },
  {
    id: MODULE_ROLE,
    name: language.role,
  },
  {
    id: MODULE_GROUP_PERMISSION,
    name: language.group_permission,
  },
  {
    id: MODULE_PERMISSION,
    name: language.permission,
  },
  {
    id: MODULE_MODULE,
    name: language.module_management,
  },
  {
    id: MODULE_API,
    name: language.api,
  },
  {
    id: MODULE_USER,
    name: language.user,
  },
  {
    id: MODULE_ICON,
    name: language.icon_homepage,
  },
  {
    id: MODULE_WHITE_LABEL,
    name: language.white_label,
  },

  {
    id: MODULE_COUNTRY,
    name: language.country,
  },
  {
    id: MODULE_CITY,
    name: language.city,
  },

  {
    id: MODULE_DISTRICT,
    name: language.district,
  },
  {
    id: MODULE_WARD,
    name: language.ward,
  },
  {
    id: MODULE_NOTIFICATION,
    name: language.notification,
  },
  {
    id: MODULE_VERSION,
    name: language.version_app,
  },
  {
    id: MODULE_ADS_PARTNER,
    name: language.ads_partner,
  },

  {
    id: MODULE_PARTNER,
    name: language.service_partner,
  },

  {
    id: MODULE_HOT_DEAL_CATEGORY,
    name: language.hot_deal_category,
  },

  {
    id: MODULE_HOT_DEAL,
    name: language.hot_deal_management,
  },
  {
    id: MODULE_PARTNER_ORDER,
    name: language.partner_order,
  },
  {
    id: MODULE_ADS_SERVICE,
    name: language.service_ads,
  },
  {
    id: MODULE_BILL_LOCK,
    name: language.bill_lock,
  },
  {
    id: MODULE_REMOTE_CONFIG,
    name: language.remote_config,
  },
  {
    id: MODULE_MERCHANT,
    name: language.merchant,
  },
  {
    id: MODULE_MERCHANT_UTILITY,
    name: language.merchant_utility,
  },
  {
    id: MODULE_MERCHANT_ORDER,
    name: language.merchant_order,
  },
  {
    id: MODULE_PLAN,
    name: language.plan,
  },
  {
    id: MODULE_STORAGE,
    name: language.storage,
  },
  {
    id: MODULE_PAYMENT_PROVIDER,
    name: language.payment_provider,
  },
  {
    id: MODULE_ORGANIZATION,
    name: language.organizations,
  },
  {
    id: MODULE_SURVEY,
    name: language.survey,
  },
  {
    id: MODULE_PROMOTION,
    name: language.promotion,
  },
];

export const MODULE_BY_ID = convertArrToObj(MODULES, "id");
