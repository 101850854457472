export const MOMO_CONFIG = "momoConfig";
export const SHOPEE_PAY_CONFIG = "shopeePayConfig";
export const VNPAY_TOKEN_CONFIG = "vnpayTokenConfig";
export const VNPAY_QR_CONFIG = "vnpayQRConfig";
export const VNPAY_GW_CONFIG = "vnpayGwConfig";
export const VNPAY_SPOS_CONFIG = "vnpaySposConfig";
export const VNPAY_SMART_SPOS_CONFIG = "vnpaySmartSposConfig";
export const VNPAY_MOBILE_BANKING_CONFIG = "vnpayMobileBankingConfig";
export const SHINHAN_VA_CONFIG = "shinhanVAConfig";
export const BIDV_VA_CONFIG = "bidvVAConfig";
export const VIETIN_VA_CONFIG = "vietinVAConfig";
export const MB_VA_CONFIG = "mbbVAConfig";
export const OCB_VA_CONFIG = "ocbVAConfig";

export const PAYMENT_CONFIGS = [
  {
    id: MOMO_CONFIG,
    key: MOMO_CONFIG,
    name: "Momo",
  },
  {
    id: SHOPEE_PAY_CONFIG,
    key: SHOPEE_PAY_CONFIG,
    name: "Shopee Pay",
  },
  {
    id: VNPAY_GW_CONFIG,
    key: VNPAY_GW_CONFIG,
    name: "VNPay GW",
  },
  {
    id: VNPAY_QR_CONFIG,
    key: VNPAY_QR_CONFIG,
    name: "VNPay QR",
  },
  {
    id: VNPAY_TOKEN_CONFIG,
    key: VNPAY_TOKEN_CONFIG,
    name: "VNPay Token",
  },
  {
    id: VNPAY_SPOS_CONFIG,
    key: VNPAY_SPOS_CONFIG,
    name: "VNPay SPOS",
  },
  {
    id: VNPAY_SMART_SPOS_CONFIG,
    key: VNPAY_SMART_SPOS_CONFIG,
    name: "VNPay Smart Spost",
  },
  {
    id: VNPAY_MOBILE_BANKING_CONFIG,
    key: VNPAY_MOBILE_BANKING_CONFIG,
    name: "VNPay Mobile banking",
  },
  {
    id: SHINHAN_VA_CONFIG,
    key: SHINHAN_VA_CONFIG,
    name: "ShinhanBank VA",
  },
  {
    id: BIDV_VA_CONFIG,
    key: BIDV_VA_CONFIG,
    name: "BIDV VA",
  },
  {
    id: VIETIN_VA_CONFIG,
    key: VIETIN_VA_CONFIG,
    name: "VietinBank VA",
  },
  {
    id: MB_VA_CONFIG,
    key: MB_VA_CONFIG,
    name: "MBBank VA",
  },
  {
    id: OCB_VA_CONFIG,
    key: OCB_VA_CONFIG,
    name: "OCB VA",
  },
];
